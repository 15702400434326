import appDetails from "./appDetails";
const navItems = {
	nav: [
		{
			title: "Home",
			link: "/home",
			show: true,
		},
		{
			title: "Cash Bids",
			link: "/cashbids",
			show: true,
		},
		{
			title: "Futures",
			link: "https://www.qtwebsitequotes.com/",
			show: true,
			external: true,
		},
		{
			title: "Weather",
			link: "/weather",
			show: true,
		},
		{
			title: "Login",
			link: appDetails?.webLink,
			show: true
		},
		{
			title: "Sign Up",
			link: "/signup",
			show: true
		}
	],
};

export default navItems;
